import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn } from '../components';
import {
    FooterOne,
    HeaderThree
} from '../containers';


const PrivacyPolicy = () => {
    return (
        <Fragment>
            <Seo title="Privay Policy" />
            <HeaderThree />
            <Breadcrumb pageName="Privacy Policy" bgImage="images/bg/footer-bg-1-1.jpg" />
            <div className="space-top space-extra-bottom">
                <div className='container'>
                    <div className='col-12'>
                        <h4>This Privacy Policy is effective as of May, 2023.</h4>
                        <p className='py-2 color-dark'>
                            At Datum AI Labs, accessible from www.datumailabs.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Datum AI Labs and how we use it.
                        </p>
                        <p className='pb-2 color-dark'>
                            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                        </p>
                        <p className='pb-2 color-dark'>
                            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Datum AI Labs. This policy is not applicable to any information collected offline or via channels other than this website.
                        </p>
                    </div>
                    <div className='col-12 py-2'>
                        <h4>Information We Collect</h4>
                        <p className='py-2 color-dark'>
                            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                        </p>
                        <p className='pb-2 color-dark'>
                            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, organization name, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                        </p>
                    </div>
                    <div className='col-12 py-2'>
                        <h4>How We Use Your Information</h4>
                        <p className='py-2 color-dark'>
                            <ol start='1'>
                                <li>We use the information we collect in various ways</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ol>
                        </p>
                        <p className='pb-2 color-dark'>
                            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, organization name, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                        </p>
                    </div>
                    <div className='col-12 py-2'>
                        <h4>Log Files</h4>
                        <p className='py-2 color-dark'>
                            Seasia Infotech follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information
                        </p>
                    </div>
                    <div className='col-12 py-2'>
                        <h4>Children's Information</h4>
                        <p className='py-2 color-dark'>
                            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                        </p>
                        <p className='pb-2 color-dark'>
                            Datum AI Labs does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                        </p>
                    </div>
                    <div className='col-12 py-2'>
                        <h4>Contact Us</h4>
                        <p className='py-2 color-dark'>
                            If anything mentioned in this privacy policy seems confusing or less clear, please feel free to reach out by writing to <a href='mailto:support@datumailabs.com'>support@datumailabs.com</a>, any kind of question or doubt concerned to our privacy policies are welcome. For your country specific queries contact our Office Addresses <a href='https://www.datumailabs.com/contact'>https://www.datumailabs.com/contact</a>.
                        </p>
                    </div>
                </div>
            </div>
            <FooterOne />
            <ScrollTopBtn />
        </Fragment>
    )
}

export default PrivacyPolicy
import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../../components';
import {
  HeaderThree,
  ServiceSingle,
  FooterOne,
} from '../../containers';

import DSData from "../../data/dataService.json";

const DataServices = ()=> (
  <Fragment>
    <Seo title="Data Services" />
    <HeaderThree/>
    <Breadcrumb prevpageName="Service" pageName="Data Services" bgImage="images/bg/footer-bg-1-1.jpg" />
    <ServiceSingle className="space-top space-extra-bottom" data={DSData}/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default DataServices;
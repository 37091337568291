import React, { Fragment } from 'react';
import {Seo,Breadcrumb,ScrollTopBtn} from '../components';
import {HeaderThree,Feedback,FooterOne} from '../containers';

const FeedbackForm = () => (
  <Fragment>
    <Seo title="Feedback Form" />
    <HeaderThree />
    <Breadcrumb pageName="Feedback" bgImage="images/bg/footer-bg-1-1.jpg" />
    <Feedback />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default FeedbackForm;
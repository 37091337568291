import React from 'react';
import { Link } from "react-router-dom";

const Logo = ({ image, title, className}) => (
  <Link className={`vs-logo ${className || ''}`} to="/">
    <img src={image} alt="Logo" width="225px" />
    {
      title && <span className='d-inline-block px-2 color-white'>{title}</span>
    }
  </Link>
);

export default Logo;

import React from 'react';
import MenuItem from "./MenuItem";

// Menu Data
import menuLinks from "../../data/MenuLinks.json";

const MainMenu = ({ className }) => {
  const wWidth = window.innerWidth;
  if (wWidth > 1199) {
    menuLinks = menuLinks.filter((mMenus) => mMenus.path !== '/contact');
  };

  return (
    <nav className={`main-menu ${className || ''}`}>
      <ul>
        {menuLinks.map((item, index) =>
          <MenuItem key={index} item={item} />
        )}
      </ul>
    </nav>
  )
};

export default MainMenu;
import React from 'react';

const InfoMedia = ({ number, image, icon, title, info, className, addr1, addr2, addr3, country }) => (
  <div className={`info-media ${className || ''}`}>
    {
      icon && (
        <div className="media-icon">
          {image && <img src={image} alt="infoIcon" />}
          {icon && <i className={icon} />}
        </div>
      )
    }
    <div className="media-body">
      {title && <span className="media-label">{title}</span>}
      {info && <div className="media-link mw-100 mx-mob-auto">{info}</div>}
      {addr1 && <div className="media-addr1">{addr1}</div>}
      {addr2 && <div className="media-addr2">{addr2}</div>}
      {country && <div className="media-country">{country}</div>}
      {number && <div className="media-link mw-100 mx-mob-auto"><a href={`tel:${number}`}>{number}</a></div>}
      {addr3 && <div className="media-country" style={{wordBreak: "break-word"}}>{addr3}</div>}
    </div>
  </div>
);


export default InfoMedia;

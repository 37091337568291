import React, { Fragment, useRef } from 'react';
import { Seo, ScrollTopBtn, TitleWrap, SecSubTitle, SecTitle, SlideNavBtn, TestimonialBoxOne } from '../components';
import {
  HeroOne,
  ProcessOne,
  TestimonialOne,
  BrandOne,
  FooterOne,
  HeaderThree,
  ServiceThree,
  AboutFive,
  TeamTwo,
} from '../containers';
import Slider from 'react-slick';

import clients from "../data/clients.json";

const HomeOne = () => {

  const sliderWrap = useRef();

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: true
        }
      }
    ]
  };

  const clientsSlider = JSON.parse(JSON.stringify(settings));
  clientsSlider.autoplay = false;

  return (
    <Fragment>
      <Seo title="Home" />
      <HeaderThree />
      <HeroOne />
      <ServiceThree className="space-top space-extra-bottom" />
      <AboutFive className="space-top space-extra-bottom" />
      {/* <TeamOne className="space-top space-extra-bottom"/> */}
      <ProcessOne className="space-top space-extra-bottom" />
      {/* <TestimonialOne className="space-top space-extra-bottom"/> */}
      <div className="space-top space-extra-bottom">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-auto text-center text-lg-start">
              <TitleWrap>
                <SecSubTitle><i className="fas fa-bring-forward" />We Value</SecSubTitle>
                <SecTitle className="h1">Our Clients</SecTitle>
              </TitleWrap>
            </div>
            <div className="col-auto d-none d-lg-block">
              <SlideNavBtn slideParent={sliderWrap} />
            </div>
          </div>
          <Slider ref={sliderWrap} className="row" {...clientsSlider}>
            {clients.map((item, index) => (
              <div key={item.id}>
                <div className="testi-style1">
                  <div className="testi-icon no-bg">
                    <img className='cpl-img' src={item.icon} style={{background: "white"}} alt='client' width={item['icon-width']} height="auto" />
                  </div>
                  <h3 className="testi-name h6">{item.name}</h3>
                  <p className="testi-text color-dark">{item.text}</p>
                  {/* <div className="testi-degi">{designation}</div> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* <BrandOne className="space-bottom"/> */}
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  )
};

export default HomeOne;
import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../../components';
import {
  ServiceSingle,
  FooterOne,
  HeaderThree
} from '../../containers';

import DQData from "../../data/dataQuality.json";

const DataQuality = ()=> (
  <Fragment>
    <Seo title="Data Quality" />
    <HeaderThree />
    <Breadcrumb prevpageName="Service" pageName="Data Quality" bgImage="images/bg/footer-bg-1-1.jpg" />
    <ServiceSingle className="space-top space-extra-bottom" data={DQData}/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default DataQuality;
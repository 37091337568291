import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn, TitleWrap, SecTitle, Button, SecSubTitle } from '../components';
import {
  FooterOne,
  HeaderThree
} from '../containers';
import Slider from 'react-slick';

import * as FaIcons from "react-icons/fa";
import * as SiIcons from "react-icons/si";
import * as BiIcons from "react-icons/bi";
import * as TbIcons from "react-icons/tb";

// Projects Data
import projectsData from '../data/projects.json';
import techData from "../data/technologies.json";
import { MdMail } from "react-icons/md";

const settings = {
  autoplay: true,
  autoplaySpeed: 8000,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200
    },
    {
      breakpoint: 992
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

const iconsSettings = structuredClone(settings);
iconsSettings.autoplay = false;
iconsSettings.slidesToShow = 6;
iconsSettings.responsive[0] = {
  breakpoint: 1200,
  settings: {
    slidesToShow: 6
  }
};
iconsSettings.responsive[1] = {
  breakpoint: 992,
  settings: {
    slidesToShow: 4
  }
};
iconsSettings.responsive[2].settings.slidesToShow = 2;
// Generataes Icons as component
const IconComponent = ({ iconName }) => {
  if (iconName) {
    if (iconName.startsWith('Fa')) {
      const FaICON = FaIcons[iconName];
      return <FaICON size="60" />
    } else if (iconName.startsWith('Si')) {
      const SiIcon = SiIcons[iconName];
      return <SiIcon size="60" />
    } else if (iconName.startsWith('Bi')) {
      const BiIcon = BiIcons[iconName];
      return <BiIcon size="60" />
    } else if (iconName.startsWith('Tb')) {
      const TbIcon = TbIcons[iconName];
      return <TbIcon size="60" />
    };
  };
}

const About = () => (
  <Fragment>
    <Seo title="About Us" />
    <HeaderThree />
    <Breadcrumb pageName="About Us" bgImage="images/bg/footer-bg-1-1.jpg" />
    <section className='app-bg-light space'>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <img className='cpl-img' src="images/about/about-1.jpg" width="100%" height="auto" alt='About Section' />
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-center flex-column py-4 py-md-0">
            <SecTitle className="text-capitalize h1 text-left">Core Team</SecTitle>
            <p className='text-dark p-2'>
              Datum AI Labs is founded by a group of passionate technologists and innovators from the Digital Financial Industry and Retail Industry to innovate new products and business models in the emerging technology stack in driving digital transformation to provide hyper personalized customer experience to increase customer engagement.
            </p>
          </div>
        </div>
        <div className="row space">
          <div className="col-lg-6 col-md-12 d-flex justify-content-center flex-column mr-lg-auto">
            <SecTitle className="text-capitalize h1 text-left">Innovative Solutions</SecTitle>
            <p className='text-dark p-2'>
              Visit the Solutions Catalog space exhibiting in-house capability and competency to carve your Digital Data strategy in transforming and scaling your Business. Be an accelerators to Retail, Banking, Health Techs, and FinTech Startup Businesses to fast-track their growth in implementing their goals.
            </p>
            <div className='col-12'>
              <Button path="/solutions">Learn More<i className="far fa-arrow-right" /></Button>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-lg-6 col-md-12 d-flex justify-content-center flex-column ml-lg-auto">
            <SecTitle className="text-capitalize h1 text-left">Operating model</SecTitle>
            <p className='text-dark p-2'>
              Visit our Operating model to understand the Blueprint of value creation in strategizing and implementing Digital Solutions. We elevate the digital Landscape in creating a growing ecosystem to help startups to unlock their potential ideas at every stage of their product evolution.
            </p>
            <div className='col-12'>
              <Button path="/process">Learn More<i className="far fa-arrow-right" /></Button>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-lg-6 col-md-12 d-flex justify-content-center flex-column py-4 py-md-0 mr-lg-auto">
            <SecTitle className="text-capitalize h1 text-left">Talent Pool​</SecTitle>
            <p className='text-dark p-2'>
              Our Talent Pool is produced by Datum AI Labs Academy wing – a group of passionate global industry technologists and innovators partnering with Datum AI Labs as a social responsibility to train and mentor the youth force across the globe to transform their careers in Digital technologies and shape the future of Datum Digital customers to innovate and build new solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="background-image" style={{ backgroundImage: 'url(images/bg/about-bg-5-1.jpg)' }}>
        <div className="container container-style1">
          <div className="row flex-row-reverse align-items-center gx-70">
            <div className="col-lg-6 col-xl">
              <img className='cpl-img' src="images/about/about-2.jpg" alt="aboutthumb" />
            </div>
            <div className="col-lg-6 col-xl-auto">
              <div className="about-box2">
                <SecSubTitle><i className="fas fa-bring-forward" />Ideate, Innovate, Craft, and Build</SecSubTitle>
                <SecTitle className="text-capitalize h1">One-stop Innovation Lab</SecTitle>
                <p className='text-dark'>Datum AI Labs is a digital transformative technology lab, helping startups and small businesses to build their digital strategy and co-create digital products with industry expertise. Our group of passionate technology experts and innovators from Digital Finance and Retail industries are devoted in creating a growing ecosystem to help startups unlock potential ideas at every stage of their product evaluation.</p>
                <div className="row gx-0 align-items-center justify-content-end mt-sm-3 pt-sm-3 mb-30 pb-10">
                  <div className="about-call m-0 no-border">
                    <div className="media-icon">
                      <MdMail size="24" />
                    </div>
                    <a style={{ fontSize: "18px" }} href="/contact">creators@datumailabs.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <TeamTwo className="space-top space-extra-bottom"/> */}
    <section className="">
      <div className={`background-image space-top space-extra-bottom`} style={{ backgroundImage: 'url(images/bg/testi-bg-4-1.jpg)' }}>
        <div className="container">
          <div className='space'>
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7 text-center text-lg-start">
                <TitleWrap>
                  <SecSubTitle><i className="fas fa-bring-forward" />Our Tech Stack</SecSubTitle>
                  <SecTitle className="text-capitalize h1">Technologies We Use</SecTitle>
                </TitleWrap>
              </div>
              {/* <div className="col-auto d-none d-lg-block">
              <div className="sec-btns2">
                <Button path="/project">Latest Projects<i className="far fa-long-arrow-right" /></Button>
                </div>
            </div> */}
            </div>
            <Slider className="row testi-style2-slide py-5 mb-5 techSlider" {...iconsSettings}>
              {techData.map((item, index) => {
                return (
                  <div key={index} className='d-flex justify-content-center'>
                    {item.type === 'icon' && <div className='techIcon text-center' title={item.name}><IconComponent iconName={item.iconName} /><h6 className='my-3'>{item.name}</h6></div>}
                    {item.type === 'image' && <div className='text-center'><img src={item.iconName} width="60px" height="60px" alt='tech-icon' /><h6 className='my-3'>{item.name}</h6></div>}
                    {item.type === 'text' && <div className='techIcon'><h3>{item.iconText}</h3></div>}
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-7 text-center text-lg-start">
              <TitleWrap>
                <SecSubTitle><i className="fas fa-bring-forward" />Our Endeavors</SecSubTitle>
                <SecTitle className="text-capitalize h1">Inspiring Business Projects In Progress</SecTitle>
              </TitleWrap>
            </div>
            {/* <div className="col-auto d-none d-lg-block">
              <div className="sec-btns2">
                <Button path="/project">Latest Projects<i className="far fa-long-arrow-right" /></Button>
              </div>
            </div> */}
          </div>
          <Slider className="row testi-style2-slide" {...settings}>
            {projectsData.map((item, index) => (
              <div key={index}>
                <div className="testi-style2">
                  <h3 className="testi-name">{item.name}</h3>
                  <div className="testi-degi mb-3">{item.designation}</div>
                  <div className="testi-body">
                    <div className="process-media-icon d-none d-xl-block">{`0${index + 1}`}</div>
                    <div className="media-body">
                      <p className="testi-text">{item.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
    <section>
      <div className={`z-index-common background-image space`} style={{ backgroundImage: 'url(images/bg/cta-bg-1-1.jpeg' }}>
        <div className="container">
          <div className="row text-center text-lg-start align-items-center justify-content-between">
            <div className="col-lg-auto">
              <SecSubTitle className="text-white">We are here to answer your questions 24/7</SecSubTitle>
              <SecTitle className="cta-title1 h1">Need A Consultation?</SecTitle>
            </div>
            <div className="col-lg-auto">
              <Button path="/contact">Connect with US<i className="far fa-arrow-right" /></Button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default About;
import React, { useState } from 'react';

import { APIService } from '../../services/api-service';

const FormFive = ({ crntMail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const formHandle = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsError(false);
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
    const response = await APIService.sendEmail({
      to: formValues.useremail,
      name: formValues.username,
      number: formValues.usernumber,
      company: formValues.userorganization,
      from: crntMail,
      subject: 'Enquiry Email',
      message: formValues.usermessage
    });
    setIsLoading(false);
    if (response.code !== 200) {
      setIsError(true);
      return
    };
    event.target.reset();
    return false;
  };

  return (
    <form action="#" className="vs-contact-form" name='enquiry' onSubmit={formHandle}>
      <div className="row gx-20">
        <div className="col-md-6 form-group">
          <input type="text" placeholder="Your Name" name='username' required />
          <i className="fal fa-user" />
        </div>
        <div className="col-md-6 form-group">
          <input type="email" placeholder="Email Address" name='useremail' required />
          <i className="fal fa-envelope" />
        </div>
        <div className="col-md-6 form-group">
          <input type="text" placeholder="Contact Number" name='usernumber' />
          <i className="fal fa-mobile" />
        </div>
        <div className="col-md-6 form-group">
          <input type="text" placeholder="Organization Name" name='userorganization' />
          <i className="fal fa-building" />
        </div>
        <div className="col-12 form-group">
          <textarea placeholder="Type Your Message" name='usermessage' required />
        </div>
        <div className="col-12">
          <button className='vs-btn' type="submit" disabled={isLoading}>
            {isLoading && 'Sending...'}
            {isError && 'Try Again'}
            {(!isLoading && !isError) && 'Submit Message'}
            <i className={`${isLoading ? "far fa-spin fa-circle-notch" : "far fa-arrow-right"}`} />
          </button>
        </div>
      </div>
    </form>
  );
}

export default FormFive;
import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../../components';
import {
  HeaderThree,
  ServiceSingle,
  FooterOne
} from '../../containers';

import DSData from "../../data/dataStreaming.json";

const DataStreaming = ()=> (
  <Fragment>
    <Seo title="Data Streaming" />
    <HeaderThree />
    <Breadcrumb prevpageName="Service" pageName="Data Streaming" bgImage="images/bg/footer-bg-1-1.jpg" />
    <ServiceSingle className="space-top space-extra-bottom" data={DSData}/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default DataStreaming;
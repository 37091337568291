import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

// Home Pages
import HomeOne from '../pages/HomeOne';
import HomeTwo from '../pages/HomeTwo';
import HomeThree from '../pages/HomeThree';

// About Page
import About from '../pages/About';

// Blog Pages
import Blog from '../pages/Blog';
import BlogGrid from '../pages/BlogGrid';
import BlogDetails from '../pages/BlogDetails';

// Pricing Plans Page
import PricePlan from '../pages/PricePlan';

// Service Pages
import Service from '../pages/Service';
import ServiceDetails from '../pages/service/DCServiceDetails/ServiceDetails';

// Team Pages
import Team from '../pages/Team';
import TeamDetails from '../pages/TeamDetails';

// Project Pages
import Project from '../pages/Project';
import ProjectDetails from '../pages/ProjectDetails';

// Contact Page
import Contact from '../pages/Contact';

// Not Fouond Page
import NotFound from '../pages/NotFound';
import DataCapture from "../pages/service/DataCapture";
import DataIntelligence from "../pages/service/DataIntelligence";
import DataQuality from "../pages/service/DataQuality";
import DataStorage from "../pages/service/DataStorage";
import DataServices from "../pages/service/DataServices";
import DataStreaming from "../pages/service/DataStreaming";
import ScrollToTopPage from "../utils/scrolltotoppage";
import Insights from "../pages/Insights";
import Process from "../pages/Process";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import DynamicQRCodeGenerator from '../components/QRCodePage/DynamicQRCodeGenerator.js';
import FeedbackForm from "../pages/FeedbackForm";
import Faq from "../pages/FaqPage.js";


const AppRouters = () => (
  <Fragment>
    <ScrollToTopPage>
      <Routes>
        <Route path="/" exact element={<HomeOne />} />
        {/* <Route path="home-two" exact element={<HomeTwo />} /> */}
        {/* <Route path="home-three" exact element={<HomeThree />} /> */}
        <Route path="about" exact element={<About />} />
        {/* <Route path="price-plan" exact element={<PricePlan />} /> */}
        <Route path="solutions" element={<Service />} />
        <Route path="data-capture" element={<DataCapture />} />
        <Route path="data-intelligence" element={<DataIntelligence />} />
        <Route path="data-quality" element={<DataQuality />} />
        <Route path="data-storage" element={<DataStorage />} />
        <Route path="data-services" element={<DataServices />} />
        <Route path="data-streaming" element={<DataStreaming />} />
        {/* <Route path="/insights" exact element={<Insights />} /> */}
        <Route path="/:name/:id/service-details" exact element={<ServiceDetails />} />
        <Route path="/project" exact element={<Project />} />
        <Route path="/project-details" exact element={<ProjectDetails />} />
        <Route path="/team" exact element={<Team />} />
        {/* <Route path="/team-details" exact element={<TeamDetails />} /> */}  
        {/* <Route path="/blog" exact element={<Blog />} /> */}
        {/* <Route path="/blog-grid" exact element={<BlogGrid />} /> */}
        {/* <Route path="/blog-details" exact element={<BlogDetails />} /> */}
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/process" exact element={<Process />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        {/* Route for QR Code Generator */}
        <Route path="/DynamicQRCodeGenerator" element={<DynamicQRCodeGenerator/>} />
        <Route path="/feedback" element={<FeedbackForm/>} />
        <Route path="/faq" element={<Faq/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTopPage>
  </Fragment>
);

export default AppRouters;
import React, { useState } from "react";
import QRCodeStyling from "qr-code-styling";

const DynamicQRCodeGenerator = () => {
  const [inputValue, setInputValue] = useState("");
  const [qrCodeColor, setQRCodeColor] = useState("#000000"); // Default color is black
  const [qrCodeBackgroundColor, setQRCodeBackgroundColor] = useState("#FFFFFF"); // Default background color is white
  const [qrCodeSize, setQRCodeSize] = useState(200); // Default size is 300
  const [qrGenerated, setQrGenerated] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleColorChange = (color, type) => {
    if (type === "foreground") {
      setQRCodeColor(color);
    } else if (type === "background") {
      setQRCodeBackgroundColor(color);
    }
  };

  const handleSizeChange = (e) => {
    setQRCodeSize(parseInt(e.target.value, 10));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const colorPalette = [
    "#000000",
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
  ];

  const generateQRCode = () => {
    if (inputValue === "") {
      alert("Please enter a valid URL to generate QR Code");
      return;
    }

    // Remove the existing QR code element if it exists
    const existingQRCode = document.getElementById("qrcode");
    if (existingQRCode.firstChild) {
      existingQRCode.removeChild(existingQRCode.firstChild);
    }

    const qrCode = new QRCodeStyling({
      width: qrCodeSize,
      height: qrCodeSize,
      data: inputValue,
      image: imageFile
        ? URL.createObjectURL(imageFile)
        : "/favicons/android-icon-36x36.png",
      dotsOptions: {
        color: qrCodeColor,
        type: "rounded",
      },
      backgroundOptions: {
        color: qrCodeBackgroundColor,
      },
      imageOptions: {
        crossOrigin: "anonymous",
      },
    });

    qrCode.append(existingQRCode); // Append to the existing QR code container
    setQrGenerated(true);
  };

  const handleSaveClick = () => {
    const qrCode = document.getElementById("qrcode").querySelector("canvas");
    const imageURL = qrCode.toDataURL("image/png");
    const a = document.createElement("a");
    a.href = imageURL;
    a.download = "QRCode.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div align="center">
      <h3>Dynamic QR Code Generator</h3>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter the URL text to generate QR"
        style={{ width: "400px", marginBottom: "20px" }}
      />
      <br />
      <label htmlFor="sizeInput" style={{ width: "400px" }}>
        Enter QR Code Size:
      </label>
      <input
        type="number"
        id="sizeInput"
        value={qrCodeSize}
        onChange={handleSizeChange}
        style={{ width: "100px", marginBottom: "20px" }}
      />
      <br />
      <label>Choose Foreground Color:</label>
      <input
        type="color"
        value={qrCodeColor}
        style={{ width: "400px", marginBottom: "20px" }}
        onChange={(e) => handleColorChange(e.target.value, "foreground")}
      />
      <br />
      <label>Choose Background Color:</label>
      <input
        type="color"
        value={qrCodeBackgroundColor}
        style={{ width: "400px", marginBottom: "20px" }}
        onChange={(e) => handleColorChange(e.target.value, "background")}
      />
      <br />
      <label>Choose Image:</label>
      <input
        type="file"
        accept="image/*"
        style={{ width: "400px", marginBottom: "20px" }}
        onChange={handleImageChange}
      />
      <br />
      <button onClick={generateQRCode} style={{ marginBottom: "20px" }}>
        Generate QR Code
      </button>
      <br />
      <div id="qrcode"></div>
      {qrGenerated && (
        <button
          onClick={handleSaveClick}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Save as Image
        </button>
      )}
    </div>
  );
};

export default DynamicQRCodeGenerator;

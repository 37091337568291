import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn, SecTitle, SecSubTitle } from '../components';
import {
  CtaOne,
  FooterOne,
  HeaderThree,
  ProcessOne,
} from '../containers';

const Process = () => (
  <Fragment>
    <Seo title="Our Process" />
    <HeaderThree />
    <Breadcrumb pageName="Our Process" bgImage="images/bg/footer-bg-1-1.jpg" />
    <ProcessOne className="space-top space-extra-bottom" noButton={true} />
    <div className="space space-extra-bottom">
      <div className="container">
        <div className="row flex-column">
          <div className='col-12 mb-4'>
            <div className='col-12 col-lg-10 ml-auto'>
              <SecSubTitle><i className="fas fa-bring-forward" />Our Operational Approach</SecSubTitle>
              <SecTitle className="h1 mb-3 pb-3 text-capitalize">Cutting-Edge Innovation Hub for Rapid Digital Integration</SecTitle>
            </div>
          </div>
          <div className='row flex-row flex-wrap'>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>01</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Agile Development Process</span>
                  <div className='process-media-link'>Our Lab has trained professionals with more than decade of experience in Agile project management to drive technology projects.</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>02</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Advanced Experimentation</span>
                  <div className='process-media-link'>Our lab provides a dynamic environment for testing and iterating digital strategies, ensuring only the most effective solutions move forward.</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>03</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Collaborative Ecosystem</span>
                  <div className='process-media-link'>We foster cross-disciplinary collaboration, bringing together experts, thought leaders, and technology enthusiasts to ideate and co-create innovative digital approaches.</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>04</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Rapid Prototyping</span>
                  <div className='process-media-link'>With cutting-edge tools and resources, we facilitate the development of rapid prototypes, enabling quick validation and refinement of digital concepts.</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>05</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Trend Anticipation</span>
                  <div className='process-media-link'>Our team of visionary experts continuously monitors technological trends, ensuring our lab remains at the forefront of innovation, ready to tackle emerging challenges.</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>06</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Customized Solutions</span>
                  <div className='process-media-link'>Recognizing that each business is unique, we tailor our innovations to align with specific organizational needs, ensuring meaningful and impactful digital integration.</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 pb-3'>
              <div className='process-info-media process-media-order'>
                <div className='process-media-icon'>07</div>
                <div className='process-media-body'>
                  <span className='process-media-label'>Learning Hub</span>
                  <div className='process-media-link'>The lab doubles as a knowledge hub, offering training, workshops, and resources to empower businesses in embracing and effectively utilizing digital solutions.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="space-extra-bottom">
      <div className="container">
        <div className="row">
          <div className='d-flex flex-column col-12 align-items-center col-lg-10 mx-lg-auto'>
            <SecTitle className="h1 pb-3 mb-0 text-capitalize">Join Us</SecTitle>
            <p className='color-dark text-lg-center'>
              In this journey of rapid digital adoption and experience the transformative power of innovation firsthand. Together, we'll navigate the digital landscape, unlock new possibilities, and position your business for sustained success.
            </p>
          </div>
        </div>
      </div>
    </div>
    <CtaOne className="space" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default Process;
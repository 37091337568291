import React from 'react';
import {
  ImageBoxFive,
  SecSubTitle,
  SecTitle,
  InfoMedia,
  List,
  Button
} from '../../components';

const AboutFive = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-6 mb-30 pb-10 pb-lg-0">
          <ImageBoxFive
            // imageOne="images/about/ab-4-1.jpg"
            // imageTwo="images/about/ab-4-2.jpg"
            // imageThree="images/about/ab-4-3.jpg"
            imageOne="images/home/home11.jpg"
            imageTwo="images/home/home2.jpg"
            imageThree="images/home/home3.jpg"
          />
        </div>
        <div className="col-lg-6 mb-30">
          <SecSubTitle><i className="fas fa-bring-forward" />ABOUT OUR COMPANY</SecSubTitle>
          <SecTitle className="text-capitalize h1">Datum AI Is A Digital Transformative Technology Lab</SecTitle>
          <p className="color-dark">
            Our mission is to ideate, innovate, and invent in order to craft your digital strategy and collaboratively create cutting-edge digital products that will redefine the boundaries of your business.
          </p>
          <List className="list-style1">
            <List.Item><i className="far fa-check-circle" />Best-in-Class Innovative Solutions Provider</List.Item>
            <List.Item><i className="far fa-check-circle" />Personalized Consultancy Services</List.Item>
            <List.Item><i className="far fa-check-circle" />Diverse Experienced Talent pool</List.Item>
            <List.Item><i className="far fa-check-circle" />Specialized in : Web | Mobile| IOT | Cloud| Blockchain | AI/ML| Big Data</List.Item>
          </List>
          <Button path="/about">Learn More<i className="far fa-arrow-right" /></Button>
        </div>
      </div>
    </div>
  </div>
);

export default AboutFive;
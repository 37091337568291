import React, { Fragment, useState } from 'react';
import { SidebarPopup } from '../';
import {
  HeaderSticky,
  Logo,
  Button,
  MobileMenu,
  MainMenu,
  IconButton
} from '../../components';

const HeaderThree = ({ imgPath }) => {

  const [sidebarShow, setSidebarShow] = useState(false);

  const handleSidebarClose = () => setSidebarShow(false);
  const handleSidebarShow = () => setSidebarShow(true);

  return (
    <Fragment>
      <SidebarPopup className="d-none d-lg-block" show={sidebarShow} onHide={handleSidebarClose} />
      <header className="vs-header header-layout2">
        <div className="header-shape"></div>
        <HeaderSticky>
          <div className="container">
            <div className="menu-area">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  {
                    imgPath ?
                      <Logo image={imgPath} className="logo-style1" /> :
                      <Logo image="images/full_logo.png" className="logo-style1" />
                  }
                </div>
                <div className="col-auto">
                  <MainMenu className="menu-style2 d-none d-lg-block" />
                  <MobileMenu imgPath={imgPath} />
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="header-btns">
                    <Button path="/contact" className="d-none d-lg-inline-block">Contact Us<i className="far fa-arrow-right" /></Button>
                    <IconButton className="style3 sideMenuToggler" onclick={handleSidebarShow} ><i className="far fa-bars" /></IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderSticky>
      </header>
    </Fragment>
  );
}

export default HeaderThree;
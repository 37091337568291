import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn, TitleWrap, SecTitle, List } from '../../components';
import {
  ServiceSingle,
  FooterOne,
  HeaderThree
} from '../../containers';
import { Link } from 'react-router-dom';

import DCData from "../../data/dataCapture.json";
import { Accordion } from 'react-bootstrap';

const ServiceDetails = () => (
  <Fragment>
    <Seo title="Data Capture" />
    <HeaderThree />
    <Breadcrumb prevpageName="Service" pageName="Data Capture" bgImage="images/bg/footer-bg-1-1.jpg" />
    <section className="space-top space-extra-bottom">
    <div className="container">
      <div className="row flex-row">
        <div className="col-12">
          <h2 className="h4">{DCData.title}</h2>
          {
            DCData.content.map(eachContent => {
              return (
                <p className='color-dark' key={eachContent.id}>{eachContent.name}</p>
              )
            })
          }
        </div>
        <div className='col-12 d-flex flex-lg-row flex-column gap-4 pt-5'>
          <div className="col-12 col-lg-4">
            <div className="row gx-0 mb-4 pb-2">
              <div className="col-12">
                <div className="service-list-box">
                  <h3 className="h5 title">Features</h3>
                  <List className="list-style3">
                    {
                      DCData.serviceFeatures.map((eachItem) => (<List.Item className="color-dark" key={eachItem.id}><i className="fal fa-check-circle" />{eachItem.name}</List.Item>))
                    }
                  </List>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-8 mx-auto'>
            {DCData.channels &&
              <Accordion defaultActiveKey={DCData.channels[0].id} className="accordion-style1 layout2">
                {DCData.channels.map(item => (
                  <Accordion.Item key={item.id} eventKey={item.id}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body><p className='color-dark'>{item.desc}</p></Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            }
          </div>
        </div>
      </div>
    </div>
    </section>
    <section className='app-bg-light space'>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-xl-6">
            <TitleWrap>
              {/* <SecSubTitle>Weekly Updates</SecSubTitle> */}
              <SecTitle className="text-capitalize h1">Solutions Catalog</SecTitle>
            </TitleWrap>
          </div>
        </div>
        <div className='row d-flex flex-row flex-wrap'>
          {
            DCData.services.map((post, index) => (

              index === 0 && (
                <div className="col-lg-6" key={post.id}>
                  <div className="service-style2">
                    <div className="service-img">
                      <span className="service-number">{'0' + post.id}</span>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={post.icon2} alt="service" width="auto" />
                      </div>
                    </div>
                    <div className="service-content">
                      <div className="service-shape background-image" style={{ backgroundImage: `url(${post.image2})` }}></div>
                      <h3 className="service-title h5"><Link to={`/dc/${post.id}/service-details`}>{post.title}</Link></h3>
                      <p className="service-text">{post.serviceDetails.seoTitle}</p>
                      <Link to={`/dc/${post.id}/service-details`} className="link-btn">Read Details<i className="far fa-arrow-right" /></Link>
                    </div>
                  </div>
                </div>
              )
            ))
          }
        </div>
      </div>
    </section>
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default ServiceDetails;
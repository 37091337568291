import React, { Fragment, useRef } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn, TitleWrap, SecSubTitle, SecTitle, SlideNavBtn, TestimonialBoxOne } from '../components';
import {
  ServiceFive,
  CtaOne,
  FooterOne,
  HeaderThree
} from '../containers';
import { Outlet } from 'react-router-dom';

const Service = () => {

  return (
    <Fragment>
      <Seo title="Solutions" />
      <HeaderThree />
      <Breadcrumb pageName="Solutions" bgImage="images/bg/footer-bg-1-1.jpg" />
      <ServiceFive className="space-top space-extra-bottom" />
      <CtaOne className="space" />
      <Outlet />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  )
};

export default Service;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FaqContainer = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [expandAll, setExpandAll] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(()=>{
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/faq`);
        setFaqs(response.data.data);
      } catch (error){
        console.error("Error fetching FAQs:", error);
      }
    }

    fetchFaqs()

    const handleResize=()=>{
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return ()=>{
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleAccordion = (index) => {
    if (expandAll) return;
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleExpandAllChange = (event) => {
    setExpandAll(event.target.checked);
    setActiveIndex(event.target.checked ? -1 : null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: "90vh",
        gap: "0.1em",
        padding: "10px",
        boxSizing: "border-box",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          width: isMobile ? "100%" : "80%",
          marginTop: "20px",
          marginBottom: "20px",
          textAlign: "left",
        }}
      >
        <label style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            checked={expandAll}
            onChange={handleExpandAllChange}
            style={{ marginRight: "10px" }}
          />
          {expandAll ? "Fold All" : "Expand All"}
          {expandAll ? <FaChevronUp style={{ marginLeft: "5px" }} /> : <FaChevronDown style={{ marginLeft: "5px" }} />}
        </label>
      </div>
      <div
        style={{
          width: isMobile ? "100%" : "80%",
        }}
      >
        {faqs.map((faq, index) => (
          <div key={index} style={{ marginBottom: "15px" }}>
            <button
              style={{
                width: "100%",
                padding: isMobile ? "10px" : "15px",
                backgroundColor: activeIndex === index || expandAll ? "#f9f9f9" : "#fff",
                border: "1px solid #ddd",
                borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                transition: "background-color 0.3s ease",
                outline: "none",
                boxSizing: "border-box",
              }}
              onClick={() => toggleAccordion(index)}
            >
              <span
                style={{
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  fontWeight: "bold",
                  color: "#555",
                  textAlign: "left",
                  flex: 1,
                }}
              >
                {faq.prompt}
              </span>
            </button>
            <div
              style={{
                maxHeight: activeIndex === index || expandAll ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease, padding 0.3s ease",
                padding: activeIndex === index || expandAll ? "15px" : "0 15px",
                backgroundColor: activeIndex === index || expandAll ? "#f9f9f9" : "#fff",
                border: activeIndex === index || expandAll ? "1px solid #ddd" : "none", 
                borderRadius: "5px",
                marginTop: activeIndex === index || expandAll ? "5px" : "0",
                color: "#666",
                fontSize: "0.95rem",
              }}
            >
              {(activeIndex === index || expandAll) && faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqContainer;
import React, { Fragment } from 'react';
import {Seo,Breadcrumb,ScrollTopBtn} from '../components';
import {HeaderThree,Faq,FooterOne} from '../containers';

const FaqPage = () => (
  <Fragment>
    <Seo title="Frequently Asked Questions" />
    <HeaderThree />
    <Breadcrumb pageName="Frequently Asked Questions" bgImage="images/bg/footer-bg-1-1.jpg" />
    <Faq />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default FaqPage;
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { List } from '../../components';
import { SidebarTwo } from '../';

// FAQ Data
import faqData from '../../data/faq.json';

const ServiceSingle = ({ data, ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row">
        <div className="col-lg-12">
          <h2 className="h4">{data.title}</h2>
          {
            data.content.map(eachContent => {
              return (
                <p className='color-dark' key={eachContent.id}>{eachContent.name}</p>
              )
            })
          }
        </div>
        <div className='col-12 d-flex flex-row gap-4 pt-5'>
          <div className="col-lg-4">
            <div className="row gx-0 mb-4 pb-2">
              <div className="col-12">
                <div className="service-list-box">
                  <h3 className="h5 title">Features</h3>
                  <List className="list-style3">
                    {
                      data.serviceFeatures.map((eachItem) => (<List.Item className="color-dark" key={eachItem.id}><i className="fal fa-check-circle" />{eachItem.name}</List.Item>))
                    }
                  </List>
                </div>
              </div>
            </div>
          </div>
          <div className='col-8 mx-auto'>
            {data.channels &&
              <Accordion defaultActiveKey={data.channels[0].id} className="accordion-style1 layout2">
                {data.channels.map(item => (
                  <Accordion.Item key={item.id} eventKey={item.id}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body><p className='color-dark'>{item.desc}</p></Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ServiceSingle;
import React, { Fragment, useEffect, useState } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn, InfoMedia, ContactWidget, TitleWrap, SecSubTitle, SecTitle, List } from '../../../components';
import {
    HeaderThree,
    FooterOne
} from '../../../containers';
import { useLocation } from 'react-router-dom';
import DCData from "../../../data/dataCapture.json"

const ServiceDetails = (data) => {
    const appLocation = useLocation();
    const [pageTitle, setPageTitle] = useState(null);
    const [activeService, setActiveService] = useState(null);


    useEffect(() => {
        const splittedVals = appLocation.pathname.split('/');
        if (String(splittedVals[1]) === 'dc') {
            const fSDeatils = DCData.services.find(fData => fData.id === parseInt(splittedVals[2]));
            setPageTitle(fSDeatils?.serviceDetails.seoTitle);
            setActiveService(fSDeatils);
        }
    }, [appLocation.pathname])


    return (
        <Fragment>
            <Seo title={`${pageTitle}`} />
            <HeaderThree imgPath="../../images/full_logo.png" />
            <Breadcrumb pageName={pageTitle} bgImage="../../images/bg/footer-bg-1-1.jpg" />
            <div className="space-top">
                <div className="container">
                    <div className="row">
                        <section className="col-lg-10 col-md-12 mx-auto">
                            {/* <div className="mb-3 pb-3"><img src="../../images/project/p-d-1-1.jpg" alt="Project" /></div> */}
                            {/* <h2 className="h4">{activeService?.text}</h2> */}
                            {/* <p>{activeService?.text}</p> */}
                            <h3 className="h5 pt-xl-3">E-Commerce Platform</h3>
                            <p>Digitalize your Business goals through Datum AI Labs Digital Partnership. Contact us to get a free quote to craft your E-Commerce App</p>
                            <p>Design and build your Digital Strategy to customize your store with our E-Commerce Platform to expand your business progressively with a personalized customer experience.</p>
                            <div className="row pt-xl-1 mb-xl-2 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="col-sm-6 mb-30 mx-auto"><img className='cpl-img' src="../../images/solutions/ecommerce.png" alt="project" /></div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <section className="app-bg-light space">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 text-center text-lg-start">
                            <TitleWrap>
                                <SecSubTitle><i className="fas fa-bring-forward" />Our Primary Attributes</SecSubTitle>
                                <SecTitle className="text-capitalize h1">Key Features</SecTitle>
                            </TitleWrap>
                        </div>
                    </div>
                    <dl className="row">
                        <dt className='col-md-6 p-3 app-color-primary fw-normal d-flex align-items-center'>
                            <img className='mr-4' src='../../images/service/mapp-1.png' width="42px" alt='service-icon' />
                            <span className='fs-5'>Mobile App to strengthen your branding</span>
                        </dt>
                        <dt className='col-md-6 col-12 p-3 app-color-primary fw-normal d-flex align-items-center'>
                            <img className='mr-4' src='../../images/service/cse-1.png' width="52px" alt='service-icon' />
                            <span className='fs-5'>Enhanced Personalized Customer Experience</span>
                        </dt>
                        <dt className='col-md-6 col-12 p-3 app-color-primary fw-normal d-flex align-items-center'>
                            <img className='mr-4' src='../../images/service/kpi-1.png' width="42px" alt='service-icon' />
                            <span className='fs-5'>Merchant KPI Insights Assistant to track your business performance</span>
                        </dt>
                        <dt className='col-md-6 col-12 p-3 app-color-primary fw-normal d-flex align-items-center'>
                            <img className='mr-4' src='../../images/service/ce-1.png' width="42px" alt='service-icon' />
                            <span className='fs-5'>Cost-effective Infrastructure (Cloud-based secure, reliable, scalable)</span>
                        </dt>
                        <dt className='col-md-6 col-12 p-3 app-color-primary fw-normal d-flex align-items-center'>
                            <img className='mr-4' src='../../images/service/ai-1.png' width="42px" alt='service-icon' />
                            <span className='fs-5'>Digital Marketing with AI Tools</span>
                        </dt>
                        <dt className='col-md-6 col-12 p-3 app-color-primary fw-normal d-flex align-items-center'>
                            <img className='mr-4' src='../../images/service/cs-1.png' width="42px" alt='service-icon' />
                            <span className='fs-5'>Strong technical team with 24/7 support</span>
                        </dt>
                    </dl>
                </div>
            </section>
            <section className='space'>
                <div className='container'>
                    <div className="col-lg-5 mx-auto text-center">
                        <TitleWrap>
                            <SecTitle className="text-capitalize h1">What We Offer</SecTitle>
                        </TitleWrap>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-12'>
                            <SecTitle className="text-capitalize fs-4">
                                <span className='d-inline-block mr-3'><img src='../../images/service/online-shop-1.png' width="48px" alt='service' /></span>
                                Online Shopping through Mobile App
                            </SecTitle>
                            <dl className="row" style={{ marginLeft: "10px" }}>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Domain with secured HTTPS</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Secured Customer login</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>User-friendly navigation</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Personalized product listing</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Order booking</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Shopping cart</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Integrated  secured payment services</span>
                                </dt>
                            </dl>
                        </div>
                        <div className='col-lg-6 col-12 px-lg-0 py-4 py-md-0 pt-4'>
                            <SecTitle className="text-capitalize fs-4">
                                <span className='d-inline-block mr-3'><img src='../../images/service/gateway.png' width="48px" alt='service' /></span>
                                Checkout & Payment Integration
                            </SecTitle>
                            <dl className="row" style={{ marginLeft: "10px" }}>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Robust Payment modules integrated with PCI-compliant gateway providers</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Square Payment Integration (US, UK, IR, Canada, Australia, France, Japan)</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>PayTM, Razorpay (India)</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Accept Global Credit Cards - Visa, Mastercard, American Express</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Merchant QR Code Scanner</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Digital Wallet (Apple pay, Google pay, Paytm)</span>
                                </dt>
                                <dt className='col-12 p-2 app-color-primary fw-normal'>
                                    <i className="far fs-4 fa-check-circle mr-2" />
                                    <span className='fs-6'>Bank Transfer</span>
                                </dt>
                            </dl>
                        </div>
                        <div className='col-lg-6 col-12 pt-5'>
                            <SecTitle className="text-capitalize fs-4">
                                <span className='d-inline-block mr-3'><img src='../../images/service/inventory-management-1.png' width="48px" alt='service' /></span>
                                Product Inventory Management
                            </SecTitle>
                            <p className='color-dark fs-6 col-11 ml-auto' style={{ marginLeft: "10px" }}>
                                Store Management and Content Management is simplified in Datum e-comm platform.
                                <br></br>
                                The Product specifications, like – categories, themes, product descriptions, sizes etc., are easily editable from the backend of your site without assistance from a developer. You will have the ability to select the featured products.  The pages of your site, their layouts, your blog and other contents of your site are manageable from the backend without any manual change in the code of the site.
                            </p>
                        </div>
                        <div className='col-lg-6 col-12 pt-5'>
                            <SecTitle className="text-capitalize fs-4">
                                <span className='d-inline-block mr-3'><img src='../../images/service/supply-chain-1.png' width="48px" alt='service' /></span>
                                Shipping Management
                            </SecTitle>
                            <p className='color-dark fs-6 col-11 ml-auto'>
                                Once you move your business digital and scaled your business, you will obviously see minimum 30% - 70% increase in the orders in your processes at different stages,  hence handling different shipment processes is scalable with the cloud based application.
                            </p>
                        </div>
                        <div className='col-lg-6 col-12 pt-5'>
                            <SecTitle className="text-capitalize fs-4">
                                <span className='d-inline-block mr-3'><img src='../../images/service/kpi-1.png' width="48px" alt='service' /></span>
                                Merchant KPI Insights Assistant
                            </SecTitle>
                            <p className='color-dark fs-6 col-11 ml-auto'>
                                Our Real time reports help you to monitor your daily business sales, Inventory stock and your purchase reports.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterOne />
            <ScrollTopBtn />
        </Fragment>
    )
}

export default ServiceDetails;
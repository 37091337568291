import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../../components';
import {
  HeaderThree,
  ServiceSingle,
  FooterOne
} from '../../containers';

import DSData from "../../data/dataStorage.json";

const DataStorage = ()=> (
  <Fragment>
    <Seo title="Data Storage" />
    <HeaderThree />
    <Breadcrumb prevpageName="Service" pageName="Data Storage" bgImage="images/bg/footer-bg-1-1.jpg" />
    <ServiceSingle className="space-top space-extra-bottom" data={DSData}/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default DataStorage;
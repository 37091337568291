import React from 'react';
import Slider from 'react-slick';
import {Button} from '../../components';

{/* <span className="hero-innertext">AI-Enhanced</span> <br/> */} // removed in title

const HeroOne = ()=> {   

  const HEROCONTENT = [
    {
      image: "images/home/hero-1-3.jpeg",
      title: <>DIGITAL INNOVATORS TO CHANGE<br/>THE FUTURE OF BUSINESS TECHNOLOGY</>,
      text: 'We apply human centric design approach to co-create innovative solutions using cutting edge technologies to solve your business challenges.  We orchestrate hyper automation customer journey to simplify business processes and increase customer engagement​.',
      btn1: {text: 'About Us', path: '/about'},
      btn2: {text: 'READ MORE',path: '/about'}
    },
    {
      image: "images/home/AI-hand.png",
      title: <>Artificial Intelligence Enabler to accelerate digital adoption </>,
      text: 'Drive Digital transformation to modernise data management approaches and adopt an AI strategy to stay abreast of contemporary trends and innovations.',
      btn1: {text: 'About Us', path: '/about'},
      btn2: {text: 'READ MORE',path: '/about'}
    },
    {
      image: "images/home/hero-1-1_dark.jpg",
      title: <>Data Insights Engineering solution provider</>,
      text: "We make emerging technology accessible to accelerate your business growth through Machine Learning and Data Insights solution to generate quantifiable results​.",
      btn1: {text: 'About Us', path: '/about'},
      btn2: {text: 'READ MORE',path: '/about'}
    }
  ];
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          arrows: false,
        }
      }
    ]
  };
  
  
  return (
    <Slider className="hero-layout1 home-banner" {...settings}>
      {HEROCONTENT.map((slide, index) => (
        <div key={index}>
          <div className="hero-inner">
            {/* <div className="hero-shape1"></div> */}
            <div className="hero-shape2"></div>
            <div className="hero-bg background-image" style={{backgroundImage: `url(${slide.image})`}}></div>
            <div className="container">
              <div className="hero-content">
                <h1 className="hero-title">{slide.title}</h1>
                <p className="hero-text">{slide.text}</p>
                <div className="hero-btns">
                  {/* <Button path={slide.btn1.path}>{slide.btn1.text}<i className="far fa-arrow-right"/></Button> */}
                  <Button path={slide.btn2.path} className="style2">{slide.btn2.text}<i className="far fa-arrow-right"/></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}    
    </Slider>
  );
}

export default HeroOne; 
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from '../../components';
import { FaStar } from 'react-icons/fa';

const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const { userId } = useParams();

  const handleRatingChange = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        userId,
        rating,
        feedback,
        email
      };

      const response = await axios.post(`${API_URL}/api/rating/add`, payload);

      setMessage(response.data.message);
      setSuccess(response.data.success);

      if (response.data.success) {
        setRating(0);
        setFeedback('');
        setEmail('');
      }
    } catch (error) {
      setMessage('Error submitting feedback.');
      setSuccess(false);
    }
  };

  const getRatingText = (ratingValue) => {
    const ratingText = [
      "We're sorry you had a bad experience. Please let us know how we can improve.",
      "We apologize for not meeting your expectations. How can we do better?",
      "Thank you for your feedback. We'd love to hear more about your experience.",
      "We're glad you had a good experience! Is there anything we could improve?",
      "Fantastic! We're thrilled you had an excellent experience. What did you love most?"
    ];
    return ratingText[ratingValue - 1] || "";
  };

  return (
    <div className="space">
      <div className="container">
        <div className="feedback-content text-center">
          <h2 className="feedback-title">Your Feedback Matters!</h2>
          <p className="feedback-text">Help us shine brighter with your feedback!</p>
          <form className="feedback-form" onSubmit={handleSubmit}>

            <div className="form-group" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
              <input 
                type="email" 
                name="email" 
                placeholder="Your Email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ width: '100%' }}
              />
            </div>
            <div className="form-group" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
              <textarea 
                name="feedback" 
                placeholder="Your Feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
                style={{ width: '100%' }}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Rate your experience: <span style={{ color: 'red' }}>*</span></label>
              <div>
                {[1, 2, 3, 4, 5].map((ratingValue) => (
                  <span
                    key={ratingValue}
                    onClick={() => handleRatingChange(ratingValue)}
                    style={{
                      fontSize: '2rem',
                      cursor: 'pointer',
                      color: ratingValue <= rating ? "#0A1A3F": 'grey',
                      transform: rating === ratingValue ? "scale(1.3)" : "scale(1)",
                      padding: '0 0.3em',
                      transition: 'color .4s ease-in-out, transform .4s ease-in-out',
                      display: 'inline-block',
                    }}
                  >
                    <FaStar />
                  </span>
                ))}
              </div>
              {rating > 0 && (
                <p style={{ 
                  marginTop: '1em',
                }}>
                  {getRatingText(rating)}
                </p>
              )}
            </div>
            <p className="catchy-line">Your thoughts are the stars that guide our improvements!</p>
            <Button type="submit">Submit Feedback</Button>
          </form>
          {message && (
            <div
              style={{
                color: success ? 'green' : 'red',
                marginTop: '1em',
                textAlign: 'center',
              }}
            >
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;

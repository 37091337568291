import React, {Fragment} from 'react';
import {
  Seo,
  Breadcrumb,
  ScrollTopBtn
} from '../components';
import {
  HeaderThree,
  NotFoundOne,
  FooterOne
} from '../containers';

const NotFound = ()=> (
  <Fragment>
    <Seo title="Page Not Found!" />
    <HeaderThree />
    <Breadcrumb pageName="Page Not Found!" bgImage="images/bg/footer-bg-1-1.jpg" />
    <NotFoundOne />
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default NotFound;
import React from 'react';
import {
  TitleWrap,
  SecSubTitle,
  SecTitle,
  ProcessBoxOne,
  Button
} from '../../components';

// Process Data
import processData from '../../data/process.json';

const ProcessOne = ({ className, noButton }) => (
  <div className={`background-image ${className || ''}`} style={{ backgroundImage: 'url(images/bg/process-bg-1-1.jpg)' }}>
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-xl-6">
          <TitleWrap>
            <SecTitle className="h1">Agile Work Management</SecTitle>
          </TitleWrap>
        </div>
      </div>
      <div className="row">
        {processData.map(item => (
          <ProcessBoxOne key={item.number} className="col-sm-6 col-lg-3 process-style1"
            arrow={item.arrow}
            title={item.title}
            text={item.text}
            icon={item.icon}
            number={item.number}
          />
        ))}
      </div>
      {
        !noButton && (
          <div className='col-12 text-center pt-5'>
            <Button path="/process">Learn More<i className="far fa-arrow-right" /></Button>
          </div>
        )
      }
    </div>
  </div>
);

export default ProcessOne;
import React, { Fragment, useEffect, useState } from "react";
import {
  Seo,
  Breadcrumb,
  ScrollTopBtn,
  List,
  Button,
  TeamBoxOne,
} from "../components";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import teamsList from "../data/teamlist.json";
import { FaLinkedin } from "react-icons/fa";
import {
  HeaderThree,
  TeamFour,
  CtaOne,
  AboutTwo,
  FooterOne,
} from "../containers";
import { Link } from "react-router-dom";

const Team = () => {
  console.log(teamsList);
  const [teamList, setTeamList] = useState(teamsList.users[0].myteam);

  const setMyTeam = ({ id, myteam }) => {
    setTeamList(myteam);
  };

  return (
    <Fragment>
      <Seo title="Our Team" />
      <HeaderThree />
      <Breadcrumb pageName="Our Team" bgImage="images/bg/footer-bg-1-1.jpg" />
      {/* <TeamFour className="space-top space-extra-bottom" /> */}
      <div className="user_bg_image w-100 py-5">
        <div className="col-xl-11 col-lg-12 mx-auto px-4">
          <div className="col-lg-12 d-flex flex-lg-row flex-column align-items-center">
            <div className="col-lg-4 col-md-5 col-sm-12 d-flex flex-column align-items-center">
              <div className="user_img sj"></div>
              <div className="d-flex flex-column gap-2 text-white text-center py-3">
                <strong className="h5 text-white mb-0">
                  <u>Sujatha Balaji</u>
                </strong>
                <strong>(Founder, Executive Director)</strong>
                <Link className="user_social"
                  to="https://www.linkedin.com/in/sujathabalaji/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin size="24" />
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <p className="text-white mb-3 text-justified">
                Meet the visionary behind Datum AI Labs, a true beacon of
                inspiration in the world of technology. As the founder and
                director, she embodies the spirit of innovation, leading
                globally distributed teams with firm determination to foster
                agility in business technology. With a distinguished 20-year
                stint in banking technology, she brings invaluable experience
                from her payments engineering background, enriching our approach
                to digital solutions.
              </p>
              <p className="text-white mb-3 text-justified">
                Her expertise in Digital Innovation Strategy and Engineering
                proficiency is unparalleled, shaping our company's future with
                cutting-edge solutions. With the heart of a Design-Thinking
                trainer, she nurtures a culture of creativity, ideation, and
                inspiring each team member to ideate fearlessly. ​
              </p>
              <p className="text-white mb-3 text-justified">
                As an Inventor, she proudly holds{" "}
                <u>
                  <a
                    className="text-white"
                    href="https://patents.google.com/?inventor=Sujatha+Balaji"
                    target="_blank"
                    rel="noreferrer"
                  >
                    six patents published in USPTO
                  </a>
                </u>
                , showcasing her remarkable contributions to the field of AI/ML
                and Blockchain.
              </p>
              <p className="text-white mb-3 text-justified">
                Voice of the leader - "In my vision, with humble start my aim is
                to navigate the complexities of the present, laying the
                foundation for a future where innovation has limitless potential
                to humanity. Every challenge, to me, is an opportunity to
                pioneer a new solution. Together, we can shape a tomorrow where
                creativity and determination pave the way for unparalleled
                progress. I’m truly excited to start my entrepreneurial journey
                with Datum AI Labs” <br /> ​ -{" "}
                <strong>Sujatha Balaji 2023</strong>
              </p>
              <p className="mb-3">
                <strong>UK GLOBAL TALENT​</strong>
                <br />
                <strong>TECHNOLOGY LEADERSHIP AND INNOVATION</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="user_bg_image w-100 py-5">
        <div className="col-xl-11 col-lg-12 mx-auto px-4">
          <div className="col-lg-12 d-flex flex-lg-row flex-column align-items-center">
            <div className="col-lg-4 col-md-5 col-sm-12 d-flex flex-column align-items-center">
              <div className="user_img bj"></div>
              <div className="d-flex flex-column gap-2 text-white text-center py-3">
                <strong className="h5 text-white mb-0">
                  <u>Balaji Jegadesan</u>
                </strong>
                <strong>(Founder, Director)</strong>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <p className="text-white mb-3 text-justified">
                Balaji supports the strategic planning and initiatives across
                all business functions while leading the business development,
                client servicing, and finance. With a sharp business acumen, he
                leverages his leadership experience in deep understanding of
                customer needs in cutting-edge technologies and have
                successfully navigated the intricacies of the sustainable energy
                needs. His strategic prowess is complemented by a commitment to
                exceptional client satisfaction, making him instrumental in
                fostering enduring relationships. Under his guidance, our
                company excels in leveraging technology to enhance business
                strategies and provide unparalleled client support, ensuring
                both technological innovation and lasting connections with our
                valued clients.
              </p>
              <p className="text-white mb-3 text-justified">
                His proficiency extends to collaborating with multiple
                educational institutes and core engineering industries to
                establish incubators for ground breaking research in AI/ML and
                sustainable energy.
              </p>
              <p className="text-white mb-3 text-justified">
                His mission transcends academia. He's passionate about
                identifying the brightest academic talents and providing them
                with unparalleled opportunities. Through internship initiatives,
                mentorship programs, and collaborative projects, he's bridging
                the gap between education and the IT industry, ensuring a
                seamless transition for promising young minds. His dedication is
                not just shaping the future of technology but also nurturing the
                next generation of innovators.
              </p>
              <p className="text-white mb-3 text-justified">
                Voice of the leader - "Our focus is on nurturing young minds to
                wield technology responsibly, ensuring a sustainable future. By
                guiding them in using technology innovatively and
                eco-consciously, we're shaping a better world. It's not just
                about their growth; it's about creating a future where
                technology and environmental harmony go hand in hand. Datum AI
                Labs we train to cultivate opportunities for skilled talents. “
                <br />​ - <strong>Balaji Jegadesan - 2023</strong>
              </p>
              <p className="mb-3">
                <strong>BUSINESS OPERATIONS AND FINANCE​</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
          <Tab.Container
            id="serviceTab"
            defaultActiveKey={teamsList.users[0].id}
          >
            <Nav className="service-tab-menu">
              {teamsList.users.map((item) => (
                <Nav.Link
                  key={item.id}
                  eventKey={item.id}
                  as="li"
                  onClick={() => setMyTeam(item)}
                >
                  <span
                    className="btn-img"
                    style={{
                      backgroundImage: `url('${item.image}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></span>
                  <span className="btn-title h6">{item.title}</span>
                  <span className="btn-text">{item.subtitle}</span>
                  <div className="team-social-wrapper">
                    {item.social.length > 0 &&
                      item.social.map(({ name, id, link }) => {
                        return (
                          <Link
                            key={id}
                            style={{ fontSize: "24px" }}
                            to={link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {name === "linkedin" && <FaLinkedin />}
                          </Link>
                        );
                      })}
                  </div>
                </Nav.Link>
              ))}
            </Nav>
            <Tab.Content>
              <div className="row">
                {teamList.length > 0 &&
                  teamList.map((item) => (
                    <div key={item.id} className="col-md-6 col-lg-4 col-xl-3">
                      <TeamBoxOne
                        path="/team-details"
                        name={item.name}
                        designation={item.designation}
                        image="images/team/t-1-1.jpg"
                      />
                    </div>
                  ))}
              </div>
            </Tab.Content>
          </Tab.Container>
        </div> */}

      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default Team;

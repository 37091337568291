import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ prevpageName, pageName, bgImage }) => (
    <div className="breadcumb-wrapper background-image" style={{ backgroundImage: `url('${bgImage}')` }}>
        <div className="container z-index-common">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">{pageName}</h1>
                {/* <div className="breadcumb-menu-wrap">
                    <ul className="breadcumb-menu">
                        {
                            prevpageName ? (
                                <li><Link to={`/${prevpageName.toLowerCase()}`}>{prevpageName}</Link></li>
                            ) : <li><Link to="/">Home</Link></li>
                        }
                        <li>{pageName}</li>
                    </ul>
                </div> */}
            </div>
        </div>
    </div>
);

export default Breadcrumb;